import styled from 'styled-components';
import colors from 'pubweb-react-components/dist/styles/colors';
import variables from '../variables';

const FooterStyles = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: space-between;
    margin: auto;
    margin-top: 80px;

    svg {
        fill: ${colors.primaryClaytonBlue};
        margin: 0px 16px 20px;
    }
    .home-link {
        margin-bottom: 30px;

        a:link, a:visited, a:hover, a:active {
            color: ${colors.primaryClaytonBlue};
            font-size: 18px;
            font-weight: 600;
            text-decoration: none;
        }
    }
    .social-media{
        margin-bottom: 30px;
    }
    .copyright {
        color: ${colors.grayscaleCoolGray03};
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        text-align: center;

        a:link, a:visited, a:hover, a:active {
            color: ${colors.grayscaleCoolGray03};
            text-decoration: none;
        }
        span { 
            margin-bottom: 20px;
        }
    }

    @media screen and (min-width: ${variables.desktop_breakpoint_width}px){
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 30px;
        max-width: 1200px;

        .copyright {
            border-top: 1px solid #979797;
            flex-direction: row;
            justify-content: space-end;
            padding-top: 20px;
            width: 100%;

            span:first-child {
                margin-right: 20px;
            }
        }
        .home-link, .social-media {
            margin-bottom: 10px;   
        }
        svg {
            margin-bottom: 10px;
            
            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

export default FooterStyles;