import React from 'react';

const BuyerIcon = ({color}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
        <g fill="none" fillRule="evenodd">
            <path stroke="#06292E" strokeWidth="1.5" d="M23.625 12.188c0 6.316-5.12 11.438-11.438 11.438C5.87 23.626.75 18.504.75 12.188.75 5.872 5.87.75 12.187.75c6.317 0 11.438 5.122 11.438 11.438z"/>
            <path fill="#E6E6E6" d="M16.188 10.895a3.944 3.944 0 1 1-7.89-.001 3.944 3.944 0 0 1 7.89 0"/>
            <path stroke="#06292E" strokeWidth="1.5" d="M16.188 10.895a3.944 3.944 0 1 1-7.89-.001 3.944 3.944 0 0 1 7.89 0z"/>
            <path stroke="#06292E" strokeWidth="1.5" d="M4.47 20.798c0-3.273 3.479-5.927 7.772-5.927s7.774 2.654 7.774 5.927"/>
            <path fill={color} d="M5.012 20.364a11.232 11.232 0 0 0 14.751-.093c-.415-2.727-3.56-4.854-7.368-4.854-3.853 0-7.027 2.176-7.383 4.947"/>
        </g>
    </svg>
};

export default BuyerIcon;