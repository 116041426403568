import React from 'react';

import colors from 'pubweb-react-components/dist/styles/colors';
import BodyCopy from 'pubweb-react-components/dist/components/Typography/BodyCopy';
import Heading from 'pubweb-react-components/dist/components/Typography/Heading';
import Button from 'pubweb-react-components/dist/components/Buttons/Button';

import PrefabSectionStyles from '../PrefabSection/PrefabSection.styled';
import BuyerIcon from '../Icons/BuyerIcon';
import QualityImg from '../../images/Quality.jpg';

import QualityFormModal from '../QualityFormModal/QualityFormModal';

class QualitySection extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showModal: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(){
        this.setState({
            showModal: true
        });

    }

    handleClose() {
        this.setState({
            showModal: false
        });
    }

    render() {
        return <div>
                <PrefabSectionStyles buttonTextColor="#241f20">
                    <div className="prefab-image">
                        <img src={QualityImg} alt="Quality comes first." />
                    </div>
                    <div className="prefab-section prefab-section-quality">
                        <div className="prefab-header">
                            <BuyerIcon color={colors.secondaryRubberDucky} />
                            <Heading headingTag="h2" headingSize="small">Homes Built to Last</Heading>
                        </div>
                        <Heading
                                headingTag="h1"
                                headingSize="xxlarge"
                                headingColor={colors.secondaryRubberDucky}>Quality comes first.</Heading>
                        <BodyCopy>
                            When it comes to getting the job done right, you know there are no shortcuts in life. 
                            That’s why when you choose Clayton Built&reg;, you choose a home that's carefully constructed on a foundation of strength, integrity and durability.
                        </BodyCopy>
                        <Button
                            className="gtm-quality-cta"
                            size="medium"
                            onButtonClick={this.handleClick}
                            fillSpace={true}
                            backgroundColor={colors.secondaryRubberDucky}
                            backgroundColorHover={colors.miscRubberDuckyHover}>Unlock Quality Upgrades</Button>
                    </div>
                </PrefabSectionStyles>
                {this.state.showModal ? <QualityFormModal onCloseClicked={this.handleClose} /> : null}
            </div>;
        }
}
 
export default QualitySection;