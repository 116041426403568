import React from 'react';

import BodyCopy from 'pubweb-react-components/dist/components/Typography/BodyCopy';

import Modal from 'pubweb-react-components/dist/components/Modal/Modal';
import PrefabFormModalStyles from './PrefabFormModal.styled';

const PrefabFormModal = ({children, onCloseClicked}) => {
    return <PrefabFormModalStyles>
        <Modal isVisible={true} onCloseClicked={onCloseClicked} showCloseButton={true}>
            <div className="prefab-modal-contents">
                {children}
                <div className="legal-footer">
                    <BodyCopy  paragraphType="small">
                    By submitting this form,  I agree that Clayton may contact me by telephone, provide me with marketing communications about Clayton Homes products and services, and share my contact information as described in <a href="https://www.claytonhomes.com/privacy">our website terms</a>.
                        
                    </BodyCopy>
                </div>
            </div>
        </Modal>
    </PrefabFormModalStyles>;
};

export default PrefabFormModal;