import React from 'react';

import colors from 'pubweb-react-components/dist/styles/colors';
import BodyCopy from 'pubweb-react-components/dist/components/Typography/BodyCopy';
import Heading from 'pubweb-react-components/dist/components/Typography/Heading';
import Button from 'pubweb-react-components/dist/components/Buttons/Button';

import PrefabSectionStyles from '../PrefabSection/PrefabSection.styled';
import BuyerIcon from '../Icons/BuyerIcon';
import StyleImg from '../../images/Style.jpg';

import StyleFormModal from '../StyleFormModal/StyleFormModal';

class StyleSection extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showModal: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(){
        this.setState({
            showModal: true
        });
    }

    handleClose() {
        this.setState({
            showModal: false
        });
    } 

    render() {
        return <div>
                <PrefabSectionStyles reverse={true}>
                    <div className="prefab-image">
                        <img src={StyleImg} alt="An eye for style." />
                    </div>
                    <div className="prefab-section">
                        <div className="prefab-header">
                            <BuyerIcon color={colors.secondaryWetCement} />
                            <Heading headingTag="h2" headingSize="small">Beautiful, Modern Design</Heading>
                        </div>
                        <Heading
                                headingTag="h1"
                                headingSize="xxlarge"
                                headingColor={colors.secondaryWetCement}>An eye for style.</Heading>
                        <BodyCopy>
                            Do you consider your home a canvas? An expression of who you are and what you’re into? Something beautiful to share with family and friends? At Clayton, we offer
                            countless features and customization options that make your home a stylish place to entertain.
                        </BodyCopy>
                        <Button
                            className="gtm-style-cta"
                            size="medium"
                            onButtonClick={this.handleClick}
                            fillSpace={true}
                            backgroundColor={colors.secondaryWetCement}
                            backgroundColorHover={colors.miscWetCementHover}>Unlock Style Upgrades</Button>
                    </div>
                </PrefabSectionStyles>
                {this.state.showModal ? <StyleFormModal onCloseClicked={this.handleClose} /> : null}
            </div>;
        }
}
 
export default StyleSection;