import styled from 'styled-components';

import colors from 'pubweb-react-components/dist/styles/colors';

import variables from '../variables';

const PrefabFormModalStyles = styled.div`
    color: ${colors.grayscaleCoolGray08};

    svg {
        display: block;
        margin-bottom: 23px;
    }
    .prefab-modal-contents{
        max-width: 375px;
        padding: 30px 8%;

        input {
            padding-right: 13px;
        }
    }
    .modal-contents.modal-content-sm {
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;
        text-align: left;
        width: 100%;
    }
    .heading-component h1 {
        font-size: 40px;
        letter-spacing: -3px;
        line-height: 1;
        margin-bottom: 22px;
    }
    .body-copy-component {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .legal-footer .body-copy-component {
        font-size: 12px;
        line-height: 1.4;
        margin-top: 30px;

        a:link, a:visited, a:hover, a:active {
            color: ${colors.grayscaleCoolGray08};
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 500px) {
        .modal-contents.modal-content-sm {
            height: auto;
            width: 375px;
        }
    }

    @media screen and (min-width: ${variables.desktop_breakpoint_width}px) {
        .prefab-modal-contents {
            max-width: none;
            width: 575px;
        }
        svg {
            margin-bottom: 30px;
        }
        .body-copy-component {
            margin-bottom: 10px;
            width: 375px
        }
        .heading-component h1 {
            font-size: 50px;
        }
        .legal-footer .body-copy-component {
            width: 390px
        }
        .modal-contents.modal-content-sm  {
            width: 575px;
        }
    }
`;

export default PrefabFormModalStyles;