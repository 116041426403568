import styled from 'styled-components';
import variables from '../variables';

const HeaderStyles = styled.div`
    margin: auto;
    max-width: ${variables.desktop_max_width}px;

    svg {
        display: block;
        height: 60px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 15px;
        width: 285px;
    }

    @media screen and (min-width: ${variables.desktop_breakpoint_width}px){
        svg {
            margin-left: 0;
        }
    }
`;

export default HeaderStyles;