import React from 'react';

import colors from 'pubweb-react-components/dist/styles/colors';
import Heading from 'pubweb-react-components/dist/components/Typography/Heading';
import BodyCopy from 'pubweb-react-components/dist/components/Typography/BodyCopy';

import PrefabFormModal from '../PrefabFormModal/PrefabFormModal';
import PrefabForm from '../PrefabForm/PrefabForm'

import QualityIcon from '../Icons/QualityIcon';

const QualityFormModal = ({onCloseClicked}) => {
    return(
        <PrefabFormModal onCloseClicked={onCloseClicked}>
                <QualityIcon />
                <Heading
                    headingTag="h1"
                    headingSize="xxlarge"
                    isCentered={true}
                    headingColor={colors.secondaryRubberDucky}>
                    See Quality Upgrades
                </Heading>
                <BodyCopy>
                    Enter your email to learn more about the quality, affordability and style of Clayton Built&reg; homes.
                </BodyCopy>
                <PrefabForm
                    buttonColor={colors.secondaryRubberDucky}
                    buttonHoverColor={colors.miscRubberDuckyHover}
                    category="Quality"
                    leadRedirectUrl="/pages/content/prefabulous/planner.html"
                    marketingRedirectUrl="/pages/content/prefabulous/planner.html"
                    />
        </PrefabFormModal>);
}

export default QualityFormModal;