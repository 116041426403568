import styled from 'styled-components';

import variables from '../../components/variables';

const IndexPageStyles = styled.div`
    .prefab-affordability, .prefab-style {
        background-color: #fbf9fa;
        padding-top: 0px;
    }
    p {
        font-family: 'acumin-pro', Helvetica, Arial, sans-serif;
    }
    .prefab-content {
        margin: auto;
        margin-top: 60px;
        text-align: center;
        .heading-component {
            margin-bottom: 20px;
        }
        span {
            display: block;
            font-size: 42px;
            letter-spacing: -1px;
            line-height: 1.04;
        }
        span:first-child {
            color: #391f6d;
        }
        span:last-child {
            color: #9a8dc8;
        }
        .body-copy-component {
            font-size: 20px;
            line-height: 1.4;
            margin: 0 auto 25px auto;
            max-width: 800px;
            padding: 0 22px;
        }
        svg {
            margin-bottom: 60px;
        }
    }    
    .clayton-built-cta {
        margin-top: 60px;
        text-align: center;

        button, a {
            margin: 0 auto;
            text-decoration: none;
        }
        button {
            height: 55px;
            padding-top: 5px;
        }
    }

    @media screen and (min-width: ${variables.desktop_breakpoint_width}px){
        .prefab-content  {
            h1{
                white-space: nowrap;
            }
            span {
                display: inline-block;
                font-size: 58px;
            }
            span:first-child {
                margin-right: 5px;
            }
            span:last-child {
                margin-left: 5px;
            }
        }
        .prefab-affordability, .prefab-quality, .prefab-style {
            padding-bottom: 90px;
            padding-top: 95px;
        }
        .prefab-style .prefab-section .heading-component h1 {
            width: 430px;
        }
    }
`;

export default IndexPageStyles;