import React from 'react';

const StyleIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="84" height="84" viewBox="0 0 84 84">
        <g fill="none" fillRule="evenodd">
            <g opacity=".14">
                <path fill="#51748B" d="M84 42c0 23.196-18.804 42-42 42C18.805 84 0 65.196 0 42S18.805 0 42 0c23.196 0 42 18.804 42 42" />
            </g>
            <path fill="#BCBEC0" d="M44.892 67a2.45 2.45 0 0 1-2.444-2.444V51.723a2.452 2.452 0 0 1 2.444-2.446 2.452 2.452 0 0 1 2.444 2.446v12.833A2.45 2.45 0 0 1 44.892 67"/>
            <path stroke="#06292E" d="M44.892 67a2.45 2.45 0 0 1-2.444-2.444V51.723a2.452 2.452 0 0 1 2.444-2.446 2.452 2.452 0 0 1 2.444 2.446v12.833A2.45 2.45 0 0 1 44.892 67zM44.892 49.277v-5.19a1.89 1.89 0 0 0-1.498-1.849l-17.17-3.643a1.89 1.89 0 0 1-1.499-1.848V30.39a1.89 1.89 0 0 1 1.891-1.889h1.73"/>
            <path fill="#CAD3D8" d="M28.392 30.333v-3.056A4.29 4.29 0 0 1 32.67 23h22.612a4.29 4.29 0 0 1 4.278 4.277v3.056c0 .521-1.544-.222-3.917-.333-1.995-.094-5 0-8 2-2-2-6-2-6-2s-13 0-13.25.333"/>
            <path fill="#51748B" d="M59.56 30.723A4.29 4.29 0 0 1 55.28 35H32.67c-2.352 0-4.277-2.924-4.277-5.277l4.25.277c0-.52 8.627-.111 11 0 1.995.094 2 0 4 2 2-1 3-2 3-2l8.917.723z"/>
            <path stroke="#06292E" d="M55.28 34.611H32.67a4.29 4.29 0 0 1-4.278-4.278v-3.056A4.29 4.29 0 0 1 32.67 23h22.61a4.29 4.29 0 0 1 4.279 4.277v3.056a4.29 4.29 0 0 1-4.278 4.278z"/>
            <path stroke="#06292E" d="M28.004 30.333h16.555c.605 0 1.185.24 1.613.669l1.026 1.026a.992.992 0 0 0 1.449-.049l.892-1.02a1.84 1.84 0 0 1 1.38-.626h8.64"/>
        </g>
    </svg>
};

export default StyleIcon;