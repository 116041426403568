import React from 'react';

const QualityIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg"  width="84" height="84" viewBox="0 0 84 84">
    <defs>
        <path id="a" d="M0 0h84v84H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <g>
            <mask id="b" fill="#fff">
                <use xlinkHref="#a"/>
            </mask>
            <path fill="#F7DA89" d="M84 42c0 23.195-18.804 42-42 42S0 65.195 0 42C0 18.804 18.804 0 42 0s42 18.804 42 42" mask="url(#b)"/>
        </g>
        <path fill="#BCBEC0" d="M28.773 63.405V26.634h36.77z"/>
        <path stroke="#06292E" d="M28.773 63.405V26.634h36.77z"/>
        <path fill="#F7DA89" d="M34.443 44.508V34.246c0-.824.668-1.493 1.492-1.493h10.261c1.33 0 1.997 1.61 1.056 2.548L36.99 45.563c-.94.94-2.547.274-2.547-1.055"/>
        <path stroke="#06292E" d="M34.443 44.508V34.246c0-.824.668-1.493 1.492-1.493h10.261c1.33 0 1.997 1.61 1.056 2.548L36.99 45.563c-.94.94-2.547.274-2.547-1.055zM32.075 55.699l2.092 2.092M57.946 29.662l2.092 2.091M45.01 43.038l2.093 2.092M51.479 36.102l2.092 2.092M38.543 49.753l2.092 2.092M30.444 26.634v34.908M34.848 26.634v3.767M39.014 26.634v3.767M43.18 26.634v3.767M47.346 26.634v3.767M51.512 26.634v3.767"/>
    </g>
</svg>
;
}

export default QualityIcon;