import React from 'react';

const AfffordabilityIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 84 84">
    <defs>
        <path id="a" d="M0 0h84v84H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <g>
            <mask id="b" fill="#fff">
                <use xlinkHref="#a"/>
            </mask>
            <path fill="#DFDDED" d="M84 42c0 23.196-18.805 42-42 42S0 65.196 0 42 18.806 0 42 0s42 18.804 42 42" mask="url(#b)"/>
        </g>
        <path fill="#A299E0" d="M50.979 27.173c3.233 1.665 9.278.312 9.423-1.615l-1.225 7.685"/>
        <path stroke="#06292E" d="M50.979 27.173c3.233 1.665 9.278.312 9.423-1.615l-1.225 7.685"/>
        <path fill="#A299E0" d="M64.328 39.004h-2.74c-1.725-7.524-9.698-13.218-19.29-13.218-10.85 0-19.646 7.283-19.646 16.267 0 5.21 2.972 9.839 7.576 12.815l-1.323 2.773c-.325.672-.023 1.489.674 1.826l2.636 1.274c.697.336 1.525.065 1.849-.607l1.362-2.86a23.185 23.185 0 0 0 6.873 1.044c2.497 0 4.876-.397 7.07-1.101l1.372 2.88a1.412 1.412 0 0 0 1.875.69l2.648-1.252a1.414 1.414 0 0 0 .657-1.887l-1.378-2.894c3.219-2.137 5.613-5.091 6.712-8.487h3.073c.647 0 1.173-.795 1.173-1.776V40.78c0-.981-.526-1.776-1.173-1.776"/>
        <path stroke="#06292E" d="M64.328 39.004h-2.74c-1.725-7.524-9.698-13.218-19.29-13.218-10.85 0-19.646 7.283-19.646 16.267 0 5.21 2.972 9.839 7.576 12.815l-1.323 2.773c-.325.672-.023 1.489.674 1.826l2.636 1.274c.697.336 1.525.065 1.849-.607l1.362-2.86a23.185 23.185 0 0 0 6.873 1.044c2.497 0 4.876-.397 7.07-1.101l1.372 2.88a1.412 1.412 0 0 0 1.875.69l2.648-1.252a1.414 1.414 0 0 0 .657-1.887l-1.378-2.894c3.219-2.137 5.613-5.091 6.712-8.487h3.073c.647 0 1.173-.795 1.173-1.776V40.78c0-.981-.526-1.776-1.173-1.776z"/>
        <path fill="#A299E0" d="M45.246 25.927c2.169.469 5.019.248 6.983-.412 1.357-.454 2.292-1.118 2.292-1.907l-.22 2.377-.423 4.58"/>
        <path stroke="#06292E" d="M45.246 25.927c2.169.469 5.019.248 6.983-.412 1.357-.454 2.292-1.118 2.292-1.907l-.22 2.377-.423 4.58M35.745 31.086a20.543 20.543 0 0 1 5.878-1.055c2.12-.071 4.16.176 6.058.69"/>
        <path fill="#06292E" d="M56.431 37.157a1.53 1.53 0 1 1-3.062-.001 1.53 1.53 0 0 1 3.062.001"/>
        <path fill="#DFDDED" d="M23.273 39.278c-5.684-.137-7.101-3.646-6.436-5.762.762-2.419 4.363-1.637 4.032.565-.348 2.307-4.053 3.828-7.355 3.558"/>
        <path stroke="#06292E" d="M23.273 39.278c-5.684-.137-7.101-3.646-6.436-5.762.762-2.419 4.363-1.637 4.032.565-.348 2.307-4.053 3.828-7.355 3.558"/>
        <path fill="#CCC" d="M43.208 16.915a3.267 3.267 0 1 0-6.534 0 3.267 3.267 0 0 0 6.534 0"/>
        <path stroke="#06292E" d="M43.208 16.915a3.267 3.267 0 1 0-6.534 0 3.267 3.267 0 0 0 6.534 0z"/>
    </g>
</svg>
}

export default AfffordabilityIcon;