import React from 'react'
import { Helmet } from 'react-helmet'
import '../styles/index.css'

import Heading from 'pubweb-react-components/dist/components/Typography/Heading'
import BodyCopy from 'pubweb-react-components/dist/components/Typography/BodyCopy'

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import StyleSection from '../../components/StyleSection/StyleSection'
import QualitySection from '../../components/QualitySection/QualitySection'
import AffordabilitySection from '../../components/AffordabilitySection/AffordabilitySection'
import InverseChevron from '../../components/Icons/InverseChevron'
import Button from 'pubweb-react-components/dist/components/Buttons/Button'
import colors from 'pubweb-react-components/dist/styles/colors'
import IndexPageStyles from './IndexPage.styled'

export class IndexPage extends React.Component {
  render() {
    return (
      <IndexPageStyles>
        <Helmet
          meta={[
            {
              name: 'viewport',
              content:
                'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
            },
            { name: 'referrer', content: 'always' },
            { charset: 'UTF-8' },
            { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
          ]}
        >
          {/* <!-- Osano Privacy Script --> */}
          <script src="https://cmp.osano.com/AzZd2RTbLigwQ5KJW/fc5c932e-d137-4fcf-ba8f-d9531da1e2ef/osano.js"></script>
          {/* <!-- End Osano Privacy Script --> */}
          <link
            rel="icon"
            type="image/x-icon"
            href="https://www.claytonhomes.com/public-assets/favicon.ico"
            sizes="any"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="https://www.claytonhomes.com/public-assets/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="https://www.claytonhomes.com/public-assets/favicon-16x16.png"
          />
          <title>Prefabulous | Clayton Homes</title>
          <link
            rel="canonical"
            href="https://www.claytonhomes.com/pages/prefabulous/"
          />
        </Helmet>
        <Header />
        <div className="prefab-content">
          <Heading headingTag="h1">
            <span>Build your</span>
            <span>dream home.</span>
          </Heading>
          <BodyCopy>
            Clayton Built&reg; homes are not one-size-fits-all. Each of our
            models can be customized with features and upgrades right for you.
            Explore your upgrade style and build your dream home below.
          </BodyCopy>
          <InverseChevron />
        </div>
        <div className="prefab-affordability">
          <AffordabilitySection />
        </div>
        <div className="prefab-style">
          <StyleSection />
        </div>
        <div className="prefab-quality">
          <QualitySection />
        </div>

        <div className="clayton-built-cta">
          <a
            href="https://www.claytonhomes.com/find-a-home"
            className="browse-clayton-built-homes"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              size="medium"
              onButtonClick={this.handleClick}
              fillSpace={false}
              backgroundColor={colors.secondarySummerNight}
              backgroundColorHover={colors.miscSummerNightHover}
            >
              BROWSE CLAYTON BUILT&reg; HOMES
            </Button>
          </a>
        </div>

        <Footer />
      </IndexPageStyles>
    )
  }
}

export default IndexPage
