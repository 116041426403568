import React from 'react';

import colors from 'pubweb-react-components/dist/styles/colors';
import BodyCopy from 'pubweb-react-components/dist/components/Typography/BodyCopy';
import Heading from 'pubweb-react-components/dist/components/Typography/Heading';
import Button from 'pubweb-react-components/dist/components/Buttons/Button';

import PrefabSectionStyles from '../PrefabSection/PrefabSection.styled';
import BuyerIcon from '../Icons/BuyerIcon';
import AffordableImg from '../../images/Affordable.jpg';

import AffordabilityFormModal from '../AffordabilityFormModal/AffordabilityFormModal';

class AffordabilitySection extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showModal: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(){
        this.setState({
            showModal: true
        });
    }

    handleClose() {
        this.setState({
            showModal: false
        });
    }

    render() {
        return <div>
                <PrefabSectionStyles>
                    <div className="prefab-image">
                        <img src={AffordableImg} alt="More for the money." />
                    </div>
                    <div className="prefab-section prefab-section-affordability">
                        <div className="prefab-header">
                            <BuyerIcon color={colors.secondarySummerNight} />
                            <Heading headingTag="h2" headingSize="small">New Affordable Homes</Heading>
                        </div>
                        <Heading
                                headingTag="h1"
                                headingSize="xxlarge"
                                headingColor={colors.secondarySummerNight}>More for the money.</Heading>
                        <BodyCopy>
                            You squeeze the most out of every dollar. You don’t see affordability as a compromise, you see it as a path to more. 
                            And we get it. At Clayton, we build homes a smarter way, so you can afford the life you’ve always dreamed of. 
                        </BodyCopy>
                        <Button
                            className="gtm-affordability-cta"
                            size="medium"
                            onButtonClick={this.handleClick}
                            fillSpace={true}
                            backgroundColor={colors.secondarySummerNight}
                            backgroundColorHover={colors.miscSummerNightHover}>Unlock Affordable Upgrades</Button>
                    </div>
                </PrefabSectionStyles>
                {this.state.showModal ? <AffordabilityFormModal onCloseClicked={this.handleClose} /> : null}
            </div>;
        }
}
 
export default AffordabilitySection;