import React from 'react';
import HeaderStyles from './Header.styled';
import ClaytonLogo from 'pubweb-react-components/dist/images/svg/ClaytonLogo';

import { ccpaScriptSource } from '../../services/settingService';


if(typeof(window) !== "undefined" && typeof(document) !== "undefined" && !document.getElementById("ccpa_script")){
    let source = ccpaScriptSource;
    let script = document.createElement('script');
    script.src = source;
    script.defer = true;
    script.id = "ccpa_script";
    script.setAttribute("data-class", "ccpa");
    script.setAttribute("data-id", "ccpa-banner");
    script.setAttribute("data-first-link-text", "Privacy Policy");
    script.setAttribute("data-first-link-href", "https://www.claytonhomes.com/privacy/");
    document.getElementsByTagName("head")[0].appendChild(script);
}

const Header = () => {
    
    return <HeaderStyles>
        <a href="https://claytonhomes.com"><ClaytonLogo  /></a>
    </HeaderStyles>;
};

export default Header;