import styled from 'styled-components';

import variables from '../variables';

const PrefabSectionStyles = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 15px;
    padding: 22px;    

    p {
        font-family: 'acumin-pro', Helvetica, Arial, sans-serif;
    }

    img {
        max-height: 100%;
        max-width: 100%;
    }
    .prefab-image{
        margin: auto;
        margin-bottom: 25px;
    }
    .prefab-header {
        position: relative;

        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        h2 {
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 2.4px;
            margin-left: 33px;
            margin-top: -2px;
        }
    }
    .prefab-section {
        margin: auto;
        max-width: ${variables.tablet_breakpoint_width}px;

        .heading-component h1 {
            font-size: 45px;
            letter-spacing: -3px;
            line-height: 120%;
            margin-bottom: 15px;
        }
        .body-copy-component {
            font-size: 20px;
            line-height: 1.4;
        }
        button {
            color: ${props => props.buttonTextColor || "#ffffff"};
            height: 55px;
            margin-top: 20px;
            padding-top: 5px;
        }
    }

    & + div > .main-modal-container .prefab-modal-contents button {
        color: ${props => props.buttonTextColor || "#ffffff"};
    }
    

    @media screen and (min-width: ${variables.desktop_breakpoint_width}px) {
        flex-direction: ${props => props.reverse ? "row-reverse" : "row"};
        justify-content: flex-start;
        margin: auto;
        max-width: ${variables.desktop_max_width}px;
        position: relative;
        width: 100%;

        .prefab-image {
            margin: 0;
            width: 55%
        }
        .prefab-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            margin-right: 90px;
            width: 35%;

            .heading-component {
                h1 {
                    font-size: 58px;
                    letter-spacing: -2px;
                    line-height: .95;
                    width: 350px;
                }
                h2 {
                    margin-left: 40px;
                }
            }
            .body-copy-component {
                font-size: 18px;
                letter-spacing: .5px;
            }
            button{
                margin-top: 30px;
                width: 330px;
            }
        }
        .prefab-section-quality,
        .prefab-section-affordability {
            padding-left: 90px;
        }
    }
`;


export default PrefabSectionStyles;
