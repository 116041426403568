import React from 'react';

import colors from 'pubweb-react-components/dist/styles/colors';
import Heading from 'pubweb-react-components/dist/components/Typography/Heading';
import BodyCopy from 'pubweb-react-components/dist/components/Typography/BodyCopy';

import PrefabFormModal from '../PrefabFormModal/PrefabFormModal';
import PrefabForm from '../PrefabForm/PrefabForm'

import StyleIcon from '../Icons/StyleIcon';

const StyleFormModal = ({onCloseClicked}) => {
    return(
        <PrefabFormModal onCloseClicked={onCloseClicked}>
                <StyleIcon />
                <Heading
                    headingTag="h1"
                    headingSize="xxlarge"
                    isCentered={true}
                    headingColor={colors.secondaryWetCement}>
                    See stylish upgrades.
                </Heading>
                <BodyCopy>
                    Enter your email to learn more about the style, quality and affordability of Clayton Built&reg; homes.
                </BodyCopy>
                <PrefabForm
                    buttonColor={colors.secondaryWetCement}
                    buttonHoverColor={colors.miscWetCementHover}
                    category="Style"
                    leadRedirectUrl="/pages/content/prefabulous/icon.html"
                    marketingRedirectUrl="/pages/content/prefabulous/icon.html"
                    />
        </PrefabFormModal>);
}

export default StyleFormModal;