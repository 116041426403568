import React from 'react'
import FooterStyles from './Footer.styled'

import FacebookIcon from 'pubweb-react-components/dist/images/svg/social-icons/FacebookIcon'
import InstagramIcon from 'pubweb-react-components/dist/images/svg/social-icons/InstagramIcon'

const Footer = () => {
  return (
    <FooterStyles>
      <div className="home-link">
        <a href="https://claytonhomes.com">ClaytonHomes.com</a>
      </div>
      <div className="social-media">
        <a target="_blank" href="https://www.instagram.com/clayton/">
          <InstagramIcon />
        </a>
        <a target="_blank" href="https://www.facebook.com/ClaytonHomes">
          <FacebookIcon />
        </a>
      </div>
      <div className="copyright">
        <span>
          &copy;1998-{new Date().getFullYear()} CMH Services, Inc. All rights
          reserved.
        </span>
        <span>
          <a href="https://www.claytonhomes.com/legal/">Legal</a> |{' '}
          <a href="https://www.claytonhomes.com/privacy/">Privacy</a> |{' '}
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
            }}
          >
            Do Not Sell or Share My Personal Information
          </a>
        </span>
      </div>
    </FooterStyles>
  )
}

export default Footer
